import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { TextInputProps } from 'services/interfaces/FormInterface';

interface MenuOption {
    label: string;
    value: string;
}

const AsynchronousAutocomplete: React.FC<TextInputProps & { apiEndPoint: string }> = ({ register, name, label = "", value = '', errors, ...rest }) => {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<MenuOption[]>([]);
    const [loading, setLoading] = useState(false);

    const fetchData = async (inputValue: string) => {
        try {
            setLoading(true);
            const response = await fetch(`${"apiEndPoint"}?search=${inputValue}`);
            const data: MenuOption[] = await response.json();

            setOptions(data);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (open) {
            fetchData('');
        } else {
            setOptions([]);
        }
    }, [open]);

    const handleOptionSelected = (value: MenuOption | null) => {
        if (value && value.label === 'Add New') {
            window.open('people/add', '_blank');
        }
    };

    return (
        <Autocomplete
            id={name}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            onInputChange={(event, inputValue) => fetchData(inputValue)}
            isOptionEqualToValue={(option: MenuOption, value: MenuOption) => option.value === value.value}
            getOptionLabel={(option: MenuOption) => option.label}
            options={[...options, { label: 'Add New', value: '' }]}
            loading={loading}
            size='small'
            fullWidth
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
            onChange={(event, value) => handleOptionSelected(value)}
        />
    );
};

export default AsynchronousAutocomplete;
