import { Container, Typography } from "@mui/material";
import React from "react";
import LoginForm from "containers/forms/LoginForm";
import useResponsive from "hooks/UseResponsive";
import { ContentStyle, ContentWrapper, RootStyle, SectionStyle } from "./style";

type Tprops = {
  page: string;
};

export const LoginPage: React.FC<Tprops> = () => {

  const mdUp = useResponsive("up", "md");

  return (
    <RootStyle>

      {mdUp && (
        <SectionStyle>
          <ContentWrapper>
            <Typography variant="h3">Welcome to Condo App</Typography>
            <Typography variant="h5">Sign in to access your account</Typography>
          </ContentWrapper>
        </SectionStyle>
      )}

      <Container>
        <ContentStyle>
          <Typography variant="h4" gutterBottom>
            CONDO
          </Typography>

          <Typography sx={{ color: "text.secondary", mb: 7 }}>
            Sign in to your account
          </Typography>

          <LoginForm />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
};

