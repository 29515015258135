import React from 'react';
import { Link } from 'react-router-dom';
import { Stack, Typography, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import GoBackButton from './GoBack';

interface BreadcrumbsProps {
  label: string,
  addnew?: boolean
  addNewLabel?: string
  goto?: string
  goback?: boolean
}

const Breadcrumb: React.FC<BreadcrumbsProps> = ({ label, addnew, addNewLabel, goto = "", goback }) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
      <Typography variant="h5">{label} Details</Typography>

      {addnew && <Button variant="contained" color="primary" startIcon={<AddIcon />} component={Link} to={goto}> New {addNewLabel || label} </Button>}
      {(goback) && <GoBackButton />}
    </Stack>
  );
};

export default Breadcrumb;
