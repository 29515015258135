import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, Container, FormControlLabel, Switch } from "@mui/material"
import { FlatType } from "common/constant";
import { SectionStyle } from "components/ContentSection";
import DropDown from "components/DropDown";
import TextInput from "components/TextInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FileUploadWithTextbox from "components/UploadFile";
import Breadcrumb from "components/Breadcrum";
import { ApiService } from "services/api.service";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AsynchronousAutocomplete from "components/Autocomplete";
import { validationSchema } from "services/validations/people";

const PeopleForm: React.FC = () => {
    const { id } = useParams();
    const [formData, setFormData] = useState<any>(null);
    const [loading, setloading] = useState<boolean>(true);

    useEffect(() => {
        const getSingleData = async () => {
            if (id) {
                setloading(true)
                const resData: any = await apiService.getApi(`unit/${id}`);
                setFormData(resData.data && resData.data[0]);
            }
            setloading(false)
        };
        getSingleData();
    }, [id]);// eslint-disable-line react-hooks/exhaustive-deps

    const apiService = new ApiService();
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting }, reset
    } = useForm<any>({
        defaultValues: formData || {},
        mode: "onChange",
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = async (data: any) => {
        console.log(data)
        //const apiResponse = await apiService.postAPi("/formData/", data);
    }

    return (

        <Container sx={{ margin: { xs: 0, sm: 'auto' }, padding: { xs: 0 } }} maxWidth={"md"}>
            <Breadcrumb label={(formData ? `Update ${formData?.person_name}` : "Add") + " Person"} goback />
            {(loading || (id && !formData)) ? <div>Please Wait...</div> :
                <SectionStyle sx={{ border: 1, borderColor: "divider" }}>
                    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                        <Box sx={{ display: 'grid', gridTemplateColumns: { sm: '1fr 1fr' }, gap: 3 }}>
                            <TextInput register={register("person_name")} name="person_name" label={"Person Name"} value={formData?.person_name} errors={errors} />
                            <TextInput register={register("nric_pp_no")} name="nric_pp_no" label="NRIC/Passport Number" value={formData?.nric_pp_no} errors={errors} />
                            <TextInput register={register("contact_no")} name="contact_no" label="Contact Number" value={formData?.contact_no || ""} errors={errors} />
                            <TextInput register={register("alternative_contact_no")} name="alternative_contact_no" label="Alternative Contact Number" value={formData?.alternative_contact_no || ""} errors={errors} />
                            <TextInput register={register("mail_id")} name="mail_id" label="Email Address" value={formData?.mail_id || ""} errors={errors} />
                            <TextInput register={register("alternative_mail_id")} name="alternative_mail_id" label="Alternative Email Address" value={formData?.alternative_mail_id || ""} errors={errors} />
                            <TextInput register={register("gender")} name="gender" label="Gender" value={formData?.gender || ""} errors={errors} />
                            <TextInput register={register("race")} name="race" label="Race" value={formData?.race || ""} errors={errors} />
                            <TextInput register={register("nationality")} name="nationality" label="Nationality" value={formData?.nationality || ""} errors={errors} />
                            <TextInput register={register("fax_no")} name="fax_no" label="Fax Number" value={formData?.fax_no || ""} errors={errors} /> 
                            {/* <FileUploadWithTextbox type="file" {...register("doc_upload")} name="doc_upload" accept=".pdf, .doc, .docx" />  */}
                            <FileUploadWithTextbox></FileUploadWithTextbox>
                            <TextInput register={register("remark")} name="remark" label="Remark" value={formData?.remark || ""} errors={errors} />
                        </Box>
                        <Box display="flex" justifyContent="space-between" pt={4}>
                            <FormControlLabel label="Person Status" value={formData?.status} control={<Switch defaultChecked color="success" />} />

                            <Box display="flex" justifyContent="flex-end" gap={5}>
                                <LoadingButton type="submit" loading={isSubmitting} variant="contained" >
                                    {(formData ? "Update" : "Create") + " Person"}
                                </LoadingButton>
                                <Button variant="outlined" onClick={() => reset()}>Cancel</Button>
                            </Box>
                        </Box>
                    </Box>

                </SectionStyle>}
        </Container >
    );
};

export default PeopleForm;