import { ChangeEvent, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TABLE_DEFAULT_ROWS } from 'common/constant';
import { ApiService } from 'services/api.service';
import { RootState } from 'redux/Reducer';

const useTable = ({
    initialState = {},
    apiService = new ApiService(),
    updateActionType = '',
    pageName = '',
    getApiUrl = ''
} = {}) => {
    const dispatch = useDispatch();
    const { tables: tableState } = useSelector((state: RootState) => state.tableData);

    const [state, setState] = useState({ ...initialState, ...tableState[pageName], page: 0, rowsPerPage: TABLE_DEFAULT_ROWS, tableRowsCount: 0, loading: true });

    const dispatchUpdateAction = (updates: any) => {
        dispatch({
            type: 'table/updateState',
            payload: {
                pageName: pageName,
                updates,
            },
        });
    };

    const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
        setState((prev) => ({
            ...prev,
            rowsPerPage: parseInt(event.target.value, TABLE_DEFAULT_ROWS),
            page: 0
        }));
    };

    const handleRequestSort = (property: string) => {
        setState((prev) => ({
            ...prev,
            order: prev.orderBy === property && prev.order === 'asc' ? 'desc' : 'asc',
            orderBy: property,
        }));
    };

    const fetchData = useCallback(async () => {
        if (getApiUrl)
            try {
                !state.loading && setState((prev) => ({ ...prev, loading: true, tableValues: null }));
                const sortingData = {
                    order: state.order,
                    orderBy: state.orderBy,
                    tabValue: state.tabValue.toString(),
                    limit: state.rowsPerPage,
                    page: state.page,
                    searchCol: state.searchCol,
                    searchValue: state.searchValue,
                };
                const resData = await apiService.getApi(getApiUrl, sortingData);
                if (resData.data)
                    setState((prev) => ({ ...prev, tableValues: resData.data }));
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setState((prev) => ({ ...prev, loading: false }));
            }

        dispatchUpdateAction({
            order: state.order,
            orderBy: state.orderBy,
            page: state.page,
            tabValue: state.tabValue,
            openForm: state.openForm,
            selected: state.selected,
            rowsPerPage: state.rowsPerPage,
            searchCol: state.searchCol,
            searchValue: state.searchValue,
        });
    }, [state.page, state.rowsPerPage, state.order, state.orderBy, state.tabValue, state.searchCol, state.searchValue,]);

    const fetchCountData = useCallback(async () => {
        if (getApiUrl)
            try {
                !state.loading && setState((prev) => ({ ...prev, loading: true, tableRowsCount: 0 }));
                const sortingData = {
                    tabValue: state.tabValue.toString(),
                    searchCol: state.searchCol,
                    searchValue: state.searchValue,
                };
                const resData = await apiService.getApiSecond(getApiUrl + '/count', sortingData);
                if (resData.data && resData.data.length > 0) {
                    const totalPage = Math.ceil(resData.data[0].count / 10) || 0;
                    setState((prev) => ({ ...prev, tableRowsCount: totalPage }));
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setState((prev) => ({ ...prev, loading: false }));
            }
    }, [state.tabValue, state.searchCol, state.searchValue]);

    useEffect(() => { fetchData() }, [fetchData]);
    useEffect(() => { fetchCountData() }, [fetchCountData]);

    /* useEffect(() => {
        dispatchUpdateAction({
            order: state.order,
            orderBy: state.orderBy,
            page: state.page,
            tabValue: state.tabValue,
            openForm: state.openForm,
            selected: state.selected,
            rowsPerPage: state.rowsPerPage || TABLE_DEFAULT_ROWS,
            searchCol: state.searchCol,
            searchValue: state.searchValue,
        });
    }, [
        state.order,
        state.orderBy,
        state.page,
        state.tabValue,
        state.openForm,
        state.selected,
        state.rowsPerPage,
        state.searchCol,
        state.searchValue,
    ]); */

    return {
        ...state,
        handleChangePage: (newPage: any) => setState((prev) => ({ ...prev, page: newPage })),
        handleRowsPerPageChange,
        handleRequestSort,
        handleTabChange: (event: SyntheticEvent, newValue: any) => setState((prev) => ({ ...prev, tabValue: newValue })),
        handleSelected: (newValue: any) => setState((prev) => ({ ...prev, selected: newValue })),
        handleTableValuesChange: (newValue: any) => setState((prev) => ({ ...prev, tableValues: newValue })),
        handleSearchColChange: (newValue: any) => setState((prev) => ({ ...prev, searchCol: newValue })),
        handleSearchValueChange: (newValue: any) => setState((prev) => ({ ...prev, searchValue: newValue })),
    };
};

export default useTable;
