import { styled } from "@mui/material/styles";
import LoginBg from "../../assets/images/sigin_bg.png";

export const RootStyle = styled("div")(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
        display: "flex",
    },
}));

export const SectionStyle = styled("div")(({ theme }) => ({
    background: `url(${LoginBg}) center center / cover no-repeat`,
    width: "100%",
    maxWidth: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
}));

export const ContentStyle = styled("div")(({ theme }) => ({
    maxWidth: 480,
    margin: "auto",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(12, 0),
}));

export const ContentWrapper = styled("div")({
    textAlign: "left",
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)",
});

/*
 
const RootStyle = styled("div")(({ theme }) => ({
  backgroundColor: "#efefef",
  backgroundImage: `url('https://images.unsplash.com/photo-1540800458874-73e6a5eed8ac?auto=format&fit=crop&q=80&w=2071&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')`,
  //backgroundImage: "linear-gradient(to bottom right, #38A2D7, #561139);",
  backgroundSize: 'cover', // You can adjust this value
  backgroundPosition: 'center', // You can adjust this value
  backgroundRepeat: 'no-repeat',
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
})); 
 
const SectionStyle = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  borderRadius: "4px",
  margin: theme.spacing(2, 2, 2, 2),
  padding: theme.spacing(7, 4, 7, 4),
  [theme.breakpoints.down("sm")]: {
    margin: theme.spacing(0),
    padding: theme.spacing(5, 2, 5, 2), // Adjust the padding for mobile devices
  },
}));
 
const ContentStyle = styled("div")(() => ({
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  textAlign: "center",
 
})); */