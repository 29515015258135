import { alpha, styled } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar, IconButton } from "@mui/material";
import SideMenu from '@mui/icons-material/FormatIndentDecrease';
import AccountPopover from "./Account";
import { DRAWER_WIDTH, BASE_WIDTH, APPBAR_MOBILE, APPBAR_DESKTOP } from "common/constant";


const RootStyle = styled(AppBar)<{ open: boolean }>(({ theme, open }) => ({
  position: "fixed",
  boxShadow: "3",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)",
  zIndex: 800,
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  background: "linear-gradient(360deg, rgb(18, 54, 66) 0%, rgb(18, 54, 66) 100%)",

  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${(open ? DRAWER_WIDTH : BASE_WIDTH) + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

export default function DashboardNavbar({ onOpenSidebar, isOpenSidebar }: any) {
  return (
    <RootStyle open={isOpenSidebar}>
      <ToolbarStyle>
        <IconButton
          onClick={onOpenSidebar}
          sx={{ mr: 1, color: "white", display: { lg: "none" } }}
        >
          <SideMenu />
        </IconButton>

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
