import Breadcrumb from "components/Breadcrum";
import CustomTable from "components/CustomTable";
import { TAB_MENU } from "common/constant";
import { Container, Card } from "@mui/material";

const TABLE_HEAD = [
    { id: "sno", label: "SNO", rowalign: "center" },
    { id: "unit_no", label: "Unit No.", allowFilter: true },
    { id: "flat_type", label: "Unit Type", allowFilter: true },
    { id: "current_owner", label: "Current Owner" },
    { id: "current_tenant", label: "Current Tenant" },
    { id: "action", label: "Action", rowalign: "center" },
];

const Unit: React.FC = () => {
    return (
        <Container>
            <Breadcrumb label={"Unit"} addnew goto="/unit/add" />
            <Card sx={{ my: 2, border: 1, borderColor: "divider" }}>
                <CustomTable apiEndpoint={"/unit"} pageName="Unit" columns={TABLE_HEAD} tabColumns={TAB_MENU} idCol={'unit_id'} />
            </Card>
        </Container>
    );
};

export default Unit;
