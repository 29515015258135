import { InventoryReducer } from "./inventoryReducer";
import { WarehouseReducer } from "./warehouseReducer";
import { ProductSerialReducer } from "./productSerialReducer";
import { AuthenticationReducer } from "./authenticationReducer";
import { SearchReducer } from "./searchReducer";
import { AnyAction, combineReducers } from "@reduxjs/toolkit";
import tableReducer from "./tableReducer";
import { RESET_STATE } from "redux/Actions/actions";

export const RootReducer = combineReducers({
    inventoryData: InventoryReducer,
    warehouseData: WarehouseReducer,
    productSerialData: ProductSerialReducer,
    authData: AuthenticationReducer,
    searchDate: SearchReducer,
    tableData: tableReducer,
});

const rootReducer = (state: any, action: AnyAction) => {
    if (action.type === RESET_STATE) {
      // Clear all state by returning the initial state
      state = undefined;
    }
  
    return RootReducer(state, action);
  };

export type RootState = ReturnType<typeof rootReducer>