import React, { useState } from 'react';
import { Box, Button, IconButton, InputAdornment, InputBase, TextField, styled } from '@mui/material';
import { Clear, Close, ImageSearch } from '@mui/icons-material';

const Input = styled('input')({
  display: 'none',
});

const FileUploadButton: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleUpload = () => {
    // Perform your file upload logic here
    console.log('Selected File:', selectedFile);
  };

  function handleFileRemove(event: any): void {
    selectedFile && setSelectedFile(null)
  }

  return (
    <Box sx={{ display: 'flex', placeContent: 'center' }}>
      <TextField
        label="File Name"
        variant="outlined"
        size='small'
        disabled
        value={selectedFile ? selectedFile.name : ''}
        fullWidth
        sx={{
          '& .MuiOutlinedInput-root': {
            /* borderRadius: '8px 0 0 8px',
            borderRight: 'none', */
            paddingRight: '0',
          },
          '& .MuiInputLabel-root': {
            borderRight: 'none',
          },
          /* '& .MuiOutlinedInput-input': {
            padding: '6.5px 14px',
            fontSize: '12px', 
          }, */
        }}
        InputProps={{
          endAdornment:
            <> {selectedFile && (
              <InputAdornment position="end">
                <IconButton onClick={handleFileRemove} sx={{ borderRadius: '0 8px 0 0' }}>
                  <Clear />
                </IconButton>
              </InputAdornment>
            )} <input
                type="file"
                accept=".pdf, .png, .jpg"
                style={{ display: 'none' }}
                onChange={handleFileChange}
                id="file-input"
              />
              <label htmlFor="file-input">
                <Button variant="contained" component="span" sx={{ boxShadow: "none", borderRadius: '0 8px 8px 0' }}>
                  Browse
                </Button>
              </label></>,
        }}
      />




    </Box>
  );
};

export default FileUploadButton;