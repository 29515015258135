import { Card, styled } from "@mui/material";

export const SectionStyle = styled(Card)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: "6px",
    boxShadow: "5",
    border: 2, borderColor: "divider",
    padding: theme.spacing(5, 4, 5, 4),
    [theme.breakpoints.down("xs")]: {
        margin: theme.spacing(0),
        padding: theme.spacing(5, 2, 5, 2), // Adjust the padding for mobile devices
    },
}));
