import axios, { AxiosError, AxiosRequestConfig, CancelTokenSource } from "axios";
import { parseAxiosError } from "common/constant";
import { BaseService } from "./base.service";
import { IErrorResponse, ISuccessResponse } from "./interfaces/ApiResponse.interface";

export class ApiService extends BaseService {

    private cancelTokenSource: CancelTokenSource | undefined;
    private cancelTokenSourceTwo: CancelTokenSource | undefined;

    public async postAPi(url: string, reqData: any): Promise<ISuccessResponse | IErrorResponse> {
        try {

            const { data } = await this.httpClient.post(url, reqData);

            return data;


        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }
    public async putApi(url: string, reqData: any): Promise<ISuccessResponse | IErrorResponse> {
        try {

            const { data } = await this.httpClient.put(url, reqData);

            return data;


        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }
    public async getApi(url: string, reqData?: any): Promise<ISuccessResponse | IErrorResponse> {
        try {
            this.cancelPreviousRequest();

            const cancelToken = axios.CancelToken.source();
            this.cancelTokenSource = cancelToken;

            const config: AxiosRequestConfig = {
                params: reqData,
                cancelToken: cancelToken.token,
            };

            const { data } = await this.httpClient.get(url, config);

            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }

    public async getApiSecond(url: string, reqData?: any): Promise<ISuccessResponse | IErrorResponse> {
        try {
            this.cancelPreviousRequestSecond();

            const cancelToken = axios.CancelToken.source();
            this.cancelTokenSourceTwo = cancelToken;

            const config: AxiosRequestConfig = {
                params: reqData,
                cancelToken: cancelToken.token,
            };

            const { data } = await this.httpClient.get(url, config);

            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }

    private cancelPreviousRequest() {
        if (this.cancelTokenSource) {
            this.cancelTokenSource.cancel('Request canceled due to new request');
        }
    }

    private cancelPreviousRequestSecond() {
        if (this.cancelTokenSourceTwo) {
            this.cancelTokenSourceTwo.cancel('Request canceled due to new request Second');
        }
    }

}