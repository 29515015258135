import { Box, Typography } from "@mui/material";
import noUser from "../assets/images/no_user.webp";

const NoData = () => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      flexDirection={"column"}
    >
      <img width={150} src={noUser} alt={"no user found"} />
      <Typography variant="body2" color={"grey.500"}>
        {`No data found`}
      </Typography>
    </Box>
  );
};

export default NoData;
