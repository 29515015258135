import React, { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  Box,
  Link,
  Drawer,
  Typography,
  Avatar,
  IconButton,
} from "@mui/material";
import Logo from "./Logo";
import NavSection from "./NavSection";
import useResponsive from "hooks/UseResponsive";
import PushPinIcon from '@mui/icons-material/PushPin';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import navConfig from "common/navConfig";
import Drawer_bg from "../../assets/images/drawerbg.png";
import { BASE_WIDTH, DRAWER_WIDTH } from "common/constant";


const RootStyle = styled("div")<{ open: boolean }>(({ theme, open }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: open ? DRAWER_WIDTH : BASE_WIDTH,
    transition: "all .3s ease-in",
  },
}));

const AccountStyle = styled("div")<{ open: boolean }>(
  ({ theme, open }: any) => ({
    display: "flex",
    alignItems: "center",
    padding: open ? theme.spacing(2, 2.5) : theme.spacing(1),
    borderRadius: open ? Number(theme.shape.borderRadius) * 1.5 : 0,
    backgroundColor: open ? theme.palette.grey[500_12] : 0,
  })
);

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar,
  hover,
  sethover,
}: any) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive("up", "lg");

  //const me = useAppSelector((state) => state.user.me);

  const show = !isDesktop || isOpenSidebar || hover;

  useEffect(() => {
    if (isOpenSidebar && !isDesktop) {
      onCloseSidebar(false);
    }
  }, [pathname, isDesktop, isOpenSidebar, onCloseSidebar]);

  const renderContent = (

    <React.Fragment>
      <Box sx={{ px: 2.5, py: 3 }}>
        {isDesktop && (
          <IconButton
            size="medium"
            onClick={() => onCloseSidebar(!isOpenSidebar)}
            style={{ float: "right", display: show ? "flex" : "none", transition: "all .3s ease-in", }}
          >
            {isOpenSidebar ? <MenuOpenIcon style={{ color: "white" }} /> :
              <PushPinIcon style={{
                color: "white",
                transition: "all .3s ease-in",
                transform: isOpenSidebar ? "rotate(3.142rad)" : "rotate(0)",
              }} />}
          </IconButton>
        )}
        <Box >
          <Logo open={show} width={45} />
        </Box>
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle open={show}>
            <Avatar alt="photoURL" />
            {show && (
              <Box sx={{ ml: 2 }}>
                <Typography variant="subtitle2" sx={{ color: "common.white" }}>
                  Saravana Kumar
                </Typography>
                <Typography
                  variant="body2"
                  className="lowercase"
                  sx={{ color: "text.secondary" }}
                >
                  Admin
                </Typography>
              </Box>
            )}
          </AccountStyle>
        </Link>
      </Box>

      <NavSection navConfig={navConfig} isMin={show} />

      <Box sx={{ flexGrow: 1 }} />
    </React.Fragment>
  );

  const drawerProps = {
    variant: 'persistent' as const,
    PaperProps: {
      sx: {
        width: isDesktop ? (show ? DRAWER_WIDTH : BASE_WIDTH) : DRAWER_WIDTH,
        zIndex: 900,
        backdropFilter: 'blur(6px)',
        WebkitBackdropFilter: 'blur(6px)',
        borderRight: 'none',
        color: 'white',
        transition: 'all .2s ease-in',
        backgroundColor: '#133643',
        backgroundImage: `url(${Drawer_bg})`,
        backgroundSize: 'auto',
        backgroundPosition: 'center bottom',
        backgroundRepeat: 'no-repeat',
      },
    },
  };

  return (
    <RootStyle
      open={isOpenSidebar}
      onMouseEnter={isDesktop ? () => sethover(true) : undefined}
      onMouseLeave={isDesktop ? () => sethover(false) : undefined}
    >
      <Drawer
        open={isDesktop || isOpenSidebar}
        onClose={() => onCloseSidebar(!isOpenSidebar)}
        {...drawerProps}
      >
        {renderContent}
      </Drawer>
    </RootStyle>
  );
}
