import Grid from '@mui/material/Grid';
import { SummaryCardType2 as SCard } from './components/summaryCard';
import { Container } from '@mui/material';
import { Diversity1, People } from '@mui/icons-material';

export default function ResponsiveGrid() {
  return (
    <Container>
      <Grid container spacing={5}>
        {/* Create 4 columns */}
        <Grid item xs={12} sm={4} md={4}><SCard color={"#1565c0"} value={"Owners : 120"} icon={<People/>} /></Grid>
        <Grid item xs={12} sm={4} md={4}><SCard color={"#e53935"} value={"Tenants : 90"} icon={<People/>}/></Grid>
        <Grid item xs={12} sm={4} md={4}> <SCard color={"#43a047"} value={"Family Count : 80"} icon={<Diversity1/>} /></Grid>
      </Grid>
    </Container>
  );
}