import React from 'react';
import { TableRow, TableCell, Skeleton } from '@mui/material';

interface LoadingTableRowProps {
  columns: number;
  row?: number;
}

const noPaddingStyle = { padding: '4px 8px', };

const LoadingTableRow: React.FC<LoadingTableRowProps> = ({ columns, row = 5 }) => (
  <>
    {Array.from({ length: row }, (_, rowIndex) => (
      <TableRow key={rowIndex}>
        {Array.from({ length: columns }, (_, colIndex) => (
          <TableCell key={colIndex} style={noPaddingStyle}>
            <Skeleton variant="text" height={30} sx={{ bgcolor: 'grey.400' }} />
          </TableCell>
        ))}
      </TableRow>
    ))}
  </>
);

export default LoadingTableRow;
