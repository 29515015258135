import { Typography } from "@mui/material";

const Logo = ({ width = 70, open = true }) => {
  return open ? <Typography variant="h4" gutterBottom>
    CONDO
  </Typography> : <Typography variant="overline" gutterBottom>
  </Typography>;
  //return <img height={width} src={open ? condo : logo} alt="logo" />;
};

export default Logo;
