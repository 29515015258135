import { styled } from "@mui/material/styles";
import {
  Toolbar,
  OutlinedInput,
  InputAdornment,
  TextField,
  MenuItem,
} from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }: any) => ({
  // height: 96,
  display: "flex",
}));

export default function SearchListToolbar({
  selectedCol,
  onSearchCol,
  SearchText,
  searchColumns,
  onSearchText,
  placeholder = "Search user...",
}: any) {

  return (
    <RootStyle sx={{ gap: 1, flexDirection: { xs: "column", md: "row" }, py: 2 }} >
      {searchColumns && (
        <TextField
          select
          size="small"
          label="Search By"
          value={selectedCol || ''}
          onChange={(event) => onSearchCol(event.target.value)}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                sx: {
                  p: 1,
                  "& .MuiMenuItem-root": {
                    typography: "body2",
                    borderRadius: 0.75,
                    marginY: 0.5,
                  },
                },
              },
            },
          }}
          sx={{ width: { md: 240, xs: 1 }, }}
        >
          <MenuItem key={"ALL"} value='All'>
            {"ALL"}
          </MenuItem>
          {searchColumns.map((option: any) => (
            <MenuItem key={option.id} value={option.id}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )}
      <OutlinedInput
        size="small"
        value={SearchText}
        fullWidth
        onChange={(event) => onSearchText(event.target.value)}
        placeholder={placeholder}
        startAdornment={
          <InputAdornment position="start">
            <SearchOutlined sx={{ color: "text.disabled", width: 20, height: 20 }} />
          </InputAdornment>
        }
      />
    </RootStyle>
  );
}
