import * as yup from 'yup';

export const validationSchema = yup.object().shape({
    person_type: yup.string().required('Person Type is required').max(255),
    person_name: yup.string().required('Person Name is required').max(255),
    nric_pp_no: yup
        .string()
        .required('NRIC/PP No is required')
        .matches(/^[A-Za-z0-9]+$/, 'Invalid NRIC/PP No').max(255),
    gender: yup.string(),
    race: yup.string().max(255),
    nationality: yup.string().max(255),
    contact_no: yup
        .string()
        .required('Contact No is required')
        .matches(/^\d+$/, 'Invalid Contact No').max(50),
    alternative_contact_no: yup
        .string()
        .matches(/^\d+$/, 'Invalid Alternative Contact No').max(50),
    mail_id: yup.string().email('Invalid email format').max(100),
    alternative_mail_id: yup.string().email('Invalid email format').max(100),
    fax_no: yup.string().max(50),
    status: yup.string(),
    remark: yup.string().max(500),
    doc_upload: yup
        .mixed()
        .test(
            'file',
            'File is required',
            (value) => value !== null
        )
        .test(
            'fileSize',
            'File size is too large. Maximum size is 5MB',
            (value) => {
                if (!value) return true; // Allow empty value (no file uploaded)

                const maxFileSize = 5 * 1024 * 1024; // 5MB

                return (value as File).size <= maxFileSize;
            }
        )
        .test(
            'fileType',
            'Only PDF or Image files are allowed',
            (value) => {
                if (!value) return true; // Allow empty value (no file uploaded)

                const allowedFileTypes = ['application/pdf', 'image/jpeg', 'image/png'];

                return allowedFileTypes.includes((value as File).type);
            }
        ),
});