
import { Navigate, Route, Routes } from "react-router-dom";
import MainLayout from "containers/MainLayout";
import Dashboard from "pages/Dashboard";
import NotFound from "pages/NotFound";
import Unit from "pages/Unit";
import UnitForm from "pages/Unit/ManageUnit";
import People from "pages/People";
import PeopleForm from "pages/People/ManagePeople";

export const PrivateRoutes = () => {

    return (
        <>
            <Routes>
                <Route path="/" element={<MainLayout />}>
                    <Route path="*" element={<Navigate to="/404" replace />} />
                    <Route index path="/" element={<Navigate to="/dashboard" replace />} />
                    <Route path="/login" element={<Navigate to="/dashboard" replace />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/unit" element={<Unit />} />
                    <Route path="/unit/add" element={<UnitForm />} />
                    <Route path="/unit/:id" element={<UnitForm />} />
                    <Route path="/people" element={<People />} />
                    <Route path="/people/add" element={<PeopleForm />} />
                    <Route path="/people/:id" element={<PeopleForm />} />
                    <Route path="/404" element={<NotFound />} />
                </Route>
            </Routes>
        </>
    );
};