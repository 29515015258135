import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Button, Typography, Container, Box } from "@mui/material";
import GoBackButton from "components/GoBack";

const ContentStyle = styled("div")(({ theme }) => ({
  height: 1,
  margin: "auto",
  minHeight: 500,
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

const NotFound = () => {
  return (
    <Container sx={{ height: 1 }}>
      <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
        <Typography variant="h3" paragraph>
          Sorry, page not found!
        </Typography>
        <Box display={"flex"} gap={5} pt={5} className="box-center">
          <Button to="/" size="large" variant="contained" component={RouterLink}>
            Go to Home
          </Button>
          <GoBackButton />
        </Box>
      </ContentStyle>
    </Container>
  );
};

export default NotFound;
