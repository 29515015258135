import { useRef, useState } from "react";
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import PreviewIcon from '@mui/icons-material/Preview';
import { Delete, Edit } from "@mui/icons-material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from "react-router-dom";
export default function UseMoreMenu({
  isDelete = false,
  isEdit = true,
  isOther = false,
  isPreview = false,
  otherLabel = "",
  otherIcon = "",
  onOther,
  previewLabel = "",
  previewIcon = "",
  onPreview,
  onDelete,
  onEdit,
  editLinkTo,
  viewLinkTo
}: any) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleClick = (path: string) => {
    navigate(path);
  };

  const onEditClicked = () => {
    onEdit();
    setIsOpen(false);
  };

  const onDeleteClicked = () => {
    onDelete();
    setIsOpen(false);
  };
  const onOtherClicked = () => {
    onOther();
    setIsOpen(false);
  };

  const onPreviewClicked = () => {
    onPreview();
    setIsOpen(false);
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVertIcon />
      </IconButton>

      {(isDelete || isEdit || isPreview) && (
        <Menu
          open={isOpen}
          anchorEl={ref.current}
          onClose={() => setIsOpen(false)}
          PaperProps={{
            sx: {
              width: 200,
              maxWidth: "100%",
              "& .MuiMenuItem-root": {
                typography: "body2",
                borderRadius: 0.75,
              },
            },
          }}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
        >
          <Stack sx={{ p: 0.5 }}>
            {isOther && (
              <MenuItem sx={{ color: "success.dark" }} onClick={onOtherClicked}>
                <ListItemIcon>
                </ListItemIcon>
                <ListItemText
                  primary={otherLabel}
                  primaryTypographyProps={{ variant: "body2", fontWeight: 600 }}
                />
              </MenuItem>
            )}
            {isPreview && (
              <MenuItem sx={{ color: "info.main" }} onClick={viewLinkTo ? () => handleClick(viewLinkTo) : onPreviewClicked}>
                <ListItemIcon>
                  <PreviewIcon sx={{ color: "info.main" }} />
                </ListItemIcon>
                <ListItemText
                  primary={previewLabel}
                  primaryTypographyProps={{ variant: "body2", fontWeight: 600 }}
                />
              </MenuItem>
            )}
            {isEdit && (
              <MenuItem sx={{ color: "text.secondary" }} onClick={editLinkTo ? () => handleClick(editLinkTo) : onEditClicked}>
                <ListItemIcon>
                  <Edit sx={{ color: "text.secondary" }} />
                </ListItemIcon>
                <ListItemText
                  primary="Edit"
                  primaryTypographyProps={{ variant: "body2", fontWeight: 600 }}
                />
              </MenuItem>
            )}
            {isDelete && (
              <MenuItem sx={{ color: "error.main" }} onClick={onDeleteClicked}>
                <ListItemIcon>
                  <Delete sx={{ color: "error.main" }} />
                </ListItemIcon>
                <ListItemText
                  primary="Delete"
                  primaryTypographyProps={{ variant: "body2", fontWeight: 600 }}
                />
              </MenuItem>
            )}
          </Stack>
        </Menu >
      )
      }
    </>
  );
}
