import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

interface DropDownProps {
    register: any;
    name: string;
    label?: string;
    value?: string;
    options: string[];
    errors?: any

}

const DropDown: React.FC<DropDownProps> = ({ register, name, errors, label, value = '', options }) => {
    return (
        <FormControl fullWidth size="small" error={!!errors && !!errors[name]}>
            <InputLabel>{label}</InputLabel>
            <Select label={label} defaultValue={value} {...register}>
                <MenuItem disabled key={'select_any'}>Please Select {label} </MenuItem>
                {options.map((option) => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </Select>
            {errors && <FormHelperText>{errors[name]?.message || ''}</FormHelperText>}
        </FormControl>
    );
};

export default DropDown;
