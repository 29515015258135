import Card from "@mui/material/Card";
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import IconButton from "@mui/material/IconButton";
import { Avatar, Stack, Typography } from "@mui/material";

export const SummaryCardType1 = ({ color }: any) => {
  return (
    <Card component={Stack}
      spacing={3}
      direction="row"
      alignContent={"center"}
      sx={{
        px: 2,
        py: 2,
        borderRadius: 2
        , backgroundColor: color ? color : "inherit", color: "white"
      }}>
      <IconButton
        size="medium"
        color="primary" // You can change the color to 'secondary' or 'default'
        aria-label="Delete"
        sx={{ px: 1.5, color: color ? color : "inherit", backgroundColor: "white" }}
      >
        <AddModeratorIcon />
      </IconButton>

      <Stack>
        <Typography variant="h6">51515</Typography>

        <Typography variant="subtitle2" >
          Weekly Sales
        </Typography>
      </Stack>

    </Card>
  );
}

export const SummaryCardType2 = ({ color, label, value, icon }: any) => {
  return (
    <Card component={Stack}
      spacing={3}
      justifyContent={"space-between"}
      direction="row"
      alignContent={"center"}
      sx={{
        px: 2,
        py: 1,
        borderRadius: 2,
        backgroundColor: color ? color : "inherit",
        color: "white",
        display: 'flex',
        alignItems: 'center',
      }}>

      <Stack>
        {value && <Typography variant="h6">{value}</Typography>}

        {label && <Typography variant="subtitle2" >
          {label}
        </Typography>}
      </Stack>

      <Avatar sx={{ backgroundColor: "white", color: "#236981" }} > { icon }</Avatar>

    </Card >
  );
}