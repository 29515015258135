import DashboardIcon from '@mui/icons-material/Dashboard';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import HailIcon from '@mui/icons-material/Hail';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { PeopleAlt } from '@mui/icons-material';

const navConfig = [
  { title: "Dashboard", path: "/dashboard", icon: <DashboardIcon />, },
  { title: "Unit Details", path: "/unit", icon: <ApartmentIcon />, },
  { title: "Accounts", path: "/accounts", icon: <AccountBalanceIcon />, },
  { title: "Support", path: "/support", icon: <SupportAgentIcon />, },
  { title: "Visitor", path: "/Visitor", icon: <HailIcon />, },
  { title: "People", path: "/people", icon: <PeopleAlt />, },
];

export default navConfig;
