import { AxiosError } from "axios";
import { parseAxiosError, showNotification, STATUS } from "common/constant";
import { BaseService } from "./base.service";
import { IErrorResponse, ISuccessResponse } from "./interfaces/ApiResponse.interface";
import { IForgotPassword, Ilogin, IResetPassword, ISignUp, IUpdate, IChangePassword } from "./interfaces/authentication.interface";


export class AuthService extends BaseService {

    public async Signup(signup: ISignUp): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post("auth/signup", signup);

            return data;
        } catch (error: any) {
            return parseAxiosError(error as AxiosError);
        }
    }

    public async Login(signin: Ilogin) {
        try {
            const { data } = await this.httpClient.post("auth/signin", signin);

            if (data.status === STATUS.SUCCESS) {
                return data;
            } else {
                showNotification(data.status, data.message);
            }
        }
        catch (error) {
            parseAxiosError(error as AxiosError);
        }
    }
    public async ForgotPassword(forgotPassword: IForgotPassword): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post("auth/forgot-password", forgotPassword);

            return data;
        }
        catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }
    public async ResetPassword(resetPassword: IResetPassword): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post("auth/reset-password", resetPassword);

            return data;
        }
        catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }

    public async ChangePassword(changePassword: IChangePassword): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post("auth/change-password", changePassword);

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }

    }

    public async allSuppliers(): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.get("/auth/admin/all-users");

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }
    public async addSuppliersByAdmin(values: ISignUp): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post("/auth/admin/add-user", values);

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }
    public async updateSuppliersByAdmin(values: IUpdate): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.put("/auth/admin/update-user", values);

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }
}