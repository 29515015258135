import TextField from '@mui/material/TextField';
import { TextInputProps } from 'services/interfaces/FormInterface';

const TextInput: React.FC<TextInputProps> = ({ register, name, label = "", value = '', errors, ...rest }) => {
    return <TextField
        name={name}
        id={name}
        label={label}
        placeholder={`Enter ${label}`}
        defaultValue={value}
        size='small'
        {...register}
        error={!!(errors && errors[name])}
        helperText={(errors && errors[name]?.message) || ''}
        {...rest}
    />;
};

export default TextInput;

/* import { FormControl, FormHelperText, InputBase, InputLabel } from "@mui/material";
import { alpha, styled } from '@mui/material/styles';


const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.mode === 'light' ? '#F3F6F9' : '#1A2027',
        border: '1px solid',
        borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
        fontSize: 14,
        width: '100%',
        padding: '8px 10px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}));

interface TextInputProps {
    register?: any;
    name: string;
    required?: boolean;
    label?: string;
    value?: any;
    errors?: any
}

const TextInput: React.FC<TextInputProps> = ({ register, name, label = "", value = null, errors, ...rest }) => {
    console.log(errors)
    return <FormControl variant="standard" error={!!(errors && errors[name])}>
        <InputLabel shrink htmlFor={name}>
            Bootstrap
        </InputLabel>
        <BootstrapInput name={name}
            id={name}
            label={label}
            placeholder={`Enter ${label}`}
            defaultValue={value}
            size='small'
            {...register}

            helperText={(errors && errors[name]?.message) || ''}
            {...rest} />
        <FormHelperText id={name}> {(errors && errors[name]?.message) || ''}</FormHelperText>
    </FormControl>
};

export default TextInput; */