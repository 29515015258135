import { applyMiddleware, legacy_createStore as createStore } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import { RootReducer } from "./Reducer";
import storageSession from "redux-persist/lib/storage/session";
import { composeWithDevTools } from "redux-devtools-extension";


const persistConfig = {
    key: "condo",
    storage:storageSession,
    // transforms: [
    //     encryptTransform({
    //       secretKey: "redux_secret_code_1234",
    //       onError: function (error) {
    //       },
    //     }),
    //   ],
};

// Persist is used for storing the value even after the page re-renders.

const persistedReducer = persistReducer(persistConfig, RootReducer);
const store: any = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));
const persistor: any = persistStore(store);

export { store, persistor };