import LoadingButton from "@mui/lab/LoadingButton";
import { Box, IconButton, InputAdornment, Link, Stack, TextField } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useNavigate } from "react-router-dom";
import { setAuthData } from "redux/Actions/actions";
import { AuthService } from "services/auth.service";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Person4Icon from '@mui/icons-material/Person4';
import Lock from '@mui/icons-material/Lock';

interface FormData {
    email: string;
    password: string;
}

interface State {
    error: Boolean;
    showPassword: boolean;
}

const validationSchema = yup.object().shape({
    email: yup.string().email('Invalid email format').required('Email is required'),
    password: yup.string().required('Password is required'),
});

const LoginForm: React.FC = () => {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, },
    } = useForm<FormData>({
        resolver: yupResolver(validationSchema),
    });

    const navigate = useNavigate();
    const Authentication = new AuthService();
    const dispatch = useDispatch();

    const onSubmit = async (data: FormData) => {
        const LoginData: FormData = {
            email: data.email,
            password: data.password
        };
        const loginResponse = await Authentication.Login(LoginData);
        if (loginResponse) {
            sessionStorage.setItem("email", loginResponse.data.email);
            sessionStorage.setItem("accessToken", loginResponse.data.token);
            sessionStorage.setItem("name",loginResponse.data.name);
            sessionStorage.setItem("role",loginResponse.data.role);
            dispatch(setAuthData(loginResponse.data));
            navigate("/dashboard");
        }
    };

    const [values, setValues] = React.useState<State>({
        showPassword: false,
        error: false,
    });

    const toggleShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    return (
        <React.Fragment>
            <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={5}>
                    {/* {values.error && (
                        <Alert
                            icon={<ErrorIcon color="error" />}
                            sx={{ bgcolor: "error.lighter" }}
                            severity="error"
                        >
                            {LOGIN.USER_ERROR}
                        </Alert>
                    )} */}
                    <TextField
                        {...register("email")}
                        id="email"
                        name="email"
                        type="text"
                        fullWidth
                        label="Email / Phone"
                        placeholder="Enter email / phone"
                        error={!!errors.email}
                        helperText={errors.email?.message}
                        autoComplete="username"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Person4Icon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        {...register("password")}
                        id="password"
                        name="password"
                        placeholder="Enter your password"
                        error={!!errors.password}
                        helperText={errors.password?.message}
                        label="Password"
                        type={values.showPassword ? "text" : "password"}
                        autoComplete="current-password"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Lock />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={toggleShowPassword}
                                        onMouseDown={toggleShowPassword}
                                        edge="end"
                                    >
                                        {values.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        fullWidth
                    />
                </Stack>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    sx={{ mt: 2, mb: 5 }}
                >
                    <Link variant="subtitle2" color={"info.main"} underline="hover">
                        Forgot Password?
                    </Link>
                </Stack>

                <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                >
                    Login
                </LoadingButton>
            </Box>
        </React.Fragment>
    );
};

export default LoginForm;