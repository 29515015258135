// tableSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TableData {
    order: string;
    orderBy: string;
    page: number;
    tabValue: number;
    openForm: boolean;
    selected: any;
    rowsPerPage: number;
    tableValues: any;
    tableRowsCount: any;
    loading: boolean;
    searchCol: string;
    searchValue: string;
}

interface TableState {
    tables: Record<string, TableData>; // Use a record for multiple tables
}

const initialState: TableState = {
    tables: {},
};

interface UpdateStatePayload {
    pageName: string;
    updates: Partial<TableState>;
}

const tableSlice = createSlice({
    name: 'table',
    initialState,
    reducers: {
        updateState: (state, action: PayloadAction<UpdateStatePayload>) => {
            const { pageName, updates } = action.payload;
            if (pageName)
                if (state.tables[pageName]) {
                    state.tables[pageName] = {
                        ...state.tables[pageName],
                        ...updates,
                    };
                }
                else if (!state.tables[pageName]) {
                    state.tables[pageName] = {} as TableData;
                }
        },
    },
});

export const { updateState, } = tableSlice.actions;

export default tableSlice.reducer;
