import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const GoBackButton = () => {
    const navigate = useNavigate();

    const goBack = () => { navigate(-1); };

    return (
        <Button variant='outlined' color="primary" onClick={goBack} startIcon={<ArrowBackIcon />}>
            Go Back
        </Button>
    );
};

export default GoBackButton;