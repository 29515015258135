import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';

import NoData from './NoData';
import TableHeader from './TableHeader';
import useTable from 'hooks/UseTable';
import LoadingTableRow from './TableShimmer';
import { TABLE_ROWS } from 'common/constant';
import { StyledTabs } from './StyledTabs';
import UserListToolbar from 'components/TableSearch';
import UseMoreMenu from './MoreButton';

interface CustomTableProps {
    apiEndpoint: string;
    columns: any;
    minWidth?: Number;
    tabColumns?: any;
    idCol?: any;
    pageName?: string;
}

const rowPaddingStyle = { padding: '4px 8px', };

const CustomTable: React.FC<CustomTableProps> = ({ pageName, apiEndpoint, columns, tabColumns, minWidth = 800, idCol, }) => {
    const table = useTable({ getApiUrl: apiEndpoint, pageName: pageName, initialState: { tabValue: tabColumns[0].value } });
    return (
        <>
            {tabColumns && <Box sx={{ borderBottom: 1, px: 2, borderColor: "divider", bgcolor: "background.neutral", }}>
                <StyledTabs onChange={table.handleTabChange} value={table.tabValue}>
                    {tabColumns.map((tabLabel: any, index: number) => (
                        <Tab key={index} disableRipple label={tabLabel.label} value={tabLabel.value} />
                    ))}
                    <Tab disableRipple label="All" value={"All"} />
                </StyledTabs>
            </Box>}

            <UserListToolbar selectedCol={table.searchCol} onSearchCol={table.handleSearchColChange} onSearchText={table.handleSearchValueChange} searchColumns={columns.filter((field: { allowFilter: any; }) => field.allowFilter).map(({ id, label }: any) => ({ id, label }))} />
            <TableContainer sx={{ minWidth: `${minWidth}` }}>
                <Table sx={{ [`& .${tableCellClasses.root}`]: { padding: 2, borderBottom: 'none' } }}>
                    <TableHeader
                        order={table.order}
                        orderBy={table.orderBy}
                        headLabel={columns}
                        onRequestSort={table.handleRequestSort}
                    />
                    <TableBody>
                        {table.loading ? (
                            <LoadingTableRow columns={columns.length} />
                        ) : table.tableValues && table.tableValues.length > 0 ? (
                            table.tableValues.map((row: any, id: number) => (
                                <TableRow hover sx={{ borderBottom: 0 }} key={id} tabIndex={-1}>
                                    {columns.map((headCell: any, index: number) => {
                                        const cellValue =
                                            headCell.id === 'sno' ? (table.page + 1) * (id + 1) :
                                                headCell.id === 'action' ? <UseMoreMenu isEdit isPreview previewLabel={`View ${pageName}`} editLinkTo={`${apiEndpoint}/${row[idCol]}`} viewLinkTo={`${apiEndpoint}/view/${row[idCol]}`} /> :
                                                    row[headCell.id] || '';

                                        return (
                                            <TableCell key={index} align={headCell.rowalign || "left"} style={rowPaddingStyle}>
                                                {cellValue}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow style={{ height: 200 }}>
                                <TableCell colSpan={columns.length}>
                                    <NoData />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Divider />
            <TablePagination
                rowsPerPageOptions={TABLE_ROWS}
                component="div"
                count={table.tableRowsCount}
                rowsPerPage={table.rowsPerPage}
                page={table.page}
                onPageChange={table.handleChangePage}
                onRowsPerPageChange={table.handleRowsPerPageChange}
            />
        </>
    );
};

export default CustomTable;
