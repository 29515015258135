import axios, { AxiosInstance, AxiosResponse } from "axios";
import { BASE_URL, showNotification } from "common/constant";

export class BaseService {
    httpClient: AxiosInstance = axios.create({ baseURL: BASE_URL });

    constructor() {

        // This interceptor is used for sending token in headers for Authorization.

        this.httpClient.interceptors.request.use((request: any) => {
            if (!request.headers?.authorization) {
                request.headers = { Authorization: String(sessionStorage.getItem("accessToken")), ...request.headers };
            }

            return request;
        });

        this.httpClient.interceptors.response.use((response: AxiosResponse) => {
            return response;
        }, (error: any) => {
            
            const err = Promise.reject(error);

            if (error.response && error.response.status === 401) {
                this.autoLogout();
            }

            return err;

        });
    }

    async autoLogout(): Promise<void> {
        sessionStorage.clear();
        showNotification("failure", "Session Expired, Kindly login again");
        window.open(window.location.origin, "_self");
    }
}
