import { AxiosError } from "axios";
import { IErrorResponse } from "../services/interfaces/ApiResponse.interface";
import ToastUtils from "components/Toaster";

export const BASE_URL = process.env.REACT_APP_BASE_URL as string;

export const DRAWER_WIDTH = 250;
export const BASE_WIDTH = 90;
export const APPBAR_MOBILE = 64;
export const APPBAR_DESKTOP = 65;

export const TABLE_ROWS = [10, 25, 50];
export const TABLE_DEFAULT_ROWS = 10;

export const STATUS = {
    SUCCESS: "success",
    FAILURE: "failure"
};

export const showNotification = (type: string, message: any): void => {
    message = message || "Something Went Wrong";

    if (type === STATUS.SUCCESS) {
        ToastUtils(message, "success")
    } else if (type === STATUS.FAILURE) {
        ToastUtils(message, "error")
    }
};

export const parseAxiosError = (error: AxiosError) => {
    try {

        if (error.isAxiosError && error.response) {

            const { status, data } = error.response;
            const responseData = data as IErrorResponse;
            console.error("parseAxiosError : " + error);

            let errorMessage = responseData.message || 'Something went wrong. Please try again later.';

            if (status === 401) {
                errorMessage = 'Unauthorized: Please check your login credentials.';
            } else if (status === 403) {
                errorMessage = 'Forbidden: You do not have permission to access this resource.';
            } else if (status === 404) {
                errorMessage = 'Not Found: The requested resource was not found.';
            }

            showNotification(STATUS.FAILURE, errorMessage);

            return { status: STATUS.FAILURE, message: errorMessage, error };

        } else if (error.request) {
            console.error('API request made, but no response received:', error.request);
            showNotification(STATUS.FAILURE, `No response received from the server. Please try again later.`);
            return { success: STATUS.FAILURE, message: error.message, error }
        } else {
            console.log(error.message)

            if (error.code != 'ERR_CANCELED')
                showNotification(STATUS.FAILURE, error.message);

            return { status: STATUS.FAILURE, message: error.message, error };
        }
    } catch (e: any) {
        console.log("parseAxiosError" + e.message)
        return { status: STATUS.FAILURE, message: e.message, error };
    }
};

const options = ['Option 1', 'Option 2', 'Option 3'];

export const FlatType = [
    "1 BHK",
    "2 BHK",
    "3 BHK",
];
export const WEEK_DAYS = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
export const ALL_DAYS = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

export const AllRoles = [
    { value: "ADMIN", label: "ADMIN" },
    { value: "SELLER", label: "SELLER" },
    { value: "MANAGER", label: "MANAGER" },
    { value: "SUPER_ADMIN", label: "SUPER ADMIN" }
];

export const TAB_MENU = [{ value: "1", label: "Active" }, { value: "0", label: "Disabled" }];

export const MENU_OPTIONS = [
    {
        label: "Home",
        icon: "eva:home-fill",
        linkTo: "/",
    },
    {
        label: "Profile",
        icon: "eva:person-fill",
        linkTo: "#",
    },
];
