import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { createRoot } from 'react-dom/client';

const Toaster = (message: string, severity: AlertProps['severity'] = 'success') => {
  const div = document.createElement('div');
  document.body.appendChild(div);

  const handleClose = () => {
    root.unmount();
    document.body.removeChild(div);
  };

  const root = createRoot(div);
  root.render(
    <Snackbar
      open={true}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <MuiAlert elevation={6} variant="filled" severity={severity} onClose={handleClose} sx={{fontSize:16}}>
        {message}
      </MuiAlert>
    </Snackbar>
  );
};

export default Toaster;

export { };
