import Breadcrumb from "components/Breadcrum";
import CustomTable from "components/CustomTable";
import { TAB_MENU } from "common/constant";
import { Container, Card } from "@mui/material";

const TABLE_HEAD = [
    { id: "sno", label: "SNO", rowalign: "center" },
    { id: "person_name", label: "Person Name", allowFilter: true },
    { id: "contact_number", label: "Contact Number", allowFilter: true },
    { id: "nric_pp_no", label: "NRIC/PP No", allowFilter: true },
    { id: "action", label: "Action", rowalign: "center" },
];

const pageName = "People"
const apiEndpoint = "/people"
const tableID = 'person_id'

const People: React.FC = () => {
    return (
        <Container>
            <Breadcrumb label={pageName} addnew addNewLabel="Person" goto="/people/add" />
            <Card sx={{ my: 2, border: 1, borderColor: "divider" }}>
                <CustomTable apiEndpoint={apiEndpoint} pageName={pageName} columns={TABLE_HEAD} tabColumns={TAB_MENU} idCol={tableID} />
            </Card>
        </Container>
    );
};

export default People;
