
import * as yup from 'yup';

export const validationSchema = yup.object().shape({
    unit_no: yup.string().required('Unit Name is required').max(50),
    blockno: yup.string().required('Block No is required').max(50),
    floorno: yup.string().required('Floor No is required').max(50),
    sqft: yup.string().required('Sq.Ft is required').max(50),
    water_code: yup.string().required('Water code is required').max(50),
    eb_code: yup.string().required('EB Code is required').max(50),
    parking_slot_count: yup.string().required('Parking Slot Count is required').max(50),
    flat_type: yup.string().required('Flat Type is required'),
});