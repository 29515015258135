import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, Container, FormControlLabel, Switch } from "@mui/material"
import { FlatType } from "common/constant";
import { SectionStyle } from "components/ContentSection";
import DropDown from "components/DropDown";
import TextInput from "components/TextInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "services/validations/unit";
import { UnitFormProps } from "services/interfaces/unit.interface";
import FileUploadWithTextbox from "components/UploadFile";
import Breadcrumb from "components/Breadcrum";
import { ApiService } from "services/api.service";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AsynchronousAutocomplete from "components/Autocomplete";

const UnitForm: React.FC<UnitFormProps> = () => {
    const { id } = useParams();
    const [unit, setUnit] = useState<any>(null);
    const [loading, setloading] = useState<boolean>(true);

    useEffect(() => {
        const getData = async () => {
            if (id) {
                setloading(true)
                const resData: any = await apiService.getApi(`unit/${id}`);
                setUnit(resData.data && resData.data[0]);
            }
            setloading(false)
        };
        getData();
    }, [id]);// eslint-disable-line react-hooks/exhaustive-deps

    const apiService = new ApiService();
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting }, reset
    } = useForm<any>({
        defaultValues: unit || {},
        mode: "onChange",
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = async (data: any) => {
        await apiService.postAPi("/unit/", data);
    }

    return (

        <Container sx={{ margin: { xs: 0, sm: 'auto' }, padding: { xs: 0 } }} maxWidth={"md"}>
            <Breadcrumb label={(unit ? `Update ${unit?.unit_no}` : "Add") + " Unit"} goback />
            {(loading || (id && !unit)) ? <div>Please Wait...</div> :
                <SectionStyle sx={{ border: 1, borderColor: "divider" }}>
                    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                        <Box sx={{ display: 'grid', gridTemplateColumns: { sm: '1fr 1fr' }, gap: 3 }}>
                            <TextInput register={register("unit_no")} name="unit_no" label={"Unit No."} value={unit?.unit_no} errors={errors} />
                            <TextInput register={register("blockno")} name="blockno" label={"Block No"} value={unit?.block_no} errors={errors} />
                            <TextInput register={register("floorno")} name="floorno" label={"Floor No"} value={unit?.floor_no} errors={errors} />
                            <TextInput register={register("sqft")} name="sqft" label={"Sq.ft Details"} value={unit?.sqft_details} errors={errors} />
                            <DropDown register={register("flat_type")} name="flat_type" label={"Flat Type"} value={unit?.flat_type} options={FlatType} errors={errors} />
                            <TextInput register={register("water_code")} name="water_code" label={"Water Code"} value={unit?.water_code} errors={errors} />
                            <TextInput register={register("eb_code")} name="eb_code" label={"EB Code"} value={unit?.eb_code} errors={errors} />
                            <TextInput register={register("parking_slot_count")} name="parking_slot_count" label={"Parking Slot Count"} value={unit?.parking_slot_count} errors={errors} />
                            <TextInput register={register("parking_slots")} name="parking_slots" label={"Parking Slots"} value={unit?.parking_slot_no} errors={errors} />
                            <AsynchronousAutocomplete name={"current_owner"} label="Current Owner" value={unit?.current_owner} apiEndPoint="" />
                            <AsynchronousAutocomplete name={"current_tenant"} label="Current Tenant" value={unit?.current_tenant} apiEndPoint="" />
                            <FileUploadWithTextbox></FileUploadWithTextbox>
                        </Box>
                        <Box display="flex" justifyContent="space-between" pt={4}>
                            <FormControlLabel label="Unit Status" value={unit?.status} control={<Switch defaultChecked color="error" />} />

                            <Box display="flex" justifyContent="flex-end" gap={5}>
                                <LoadingButton type="submit" loading={isSubmitting} variant="contained" >
                                    {(unit ? "Update" : "Create") + " Unit"}
                                </LoadingButton>
                                <Button variant="outlined" onClick={() => reset()}>Cancel</Button>
                            </Box>
                        </Box>
                    </Box>

                </SectionStyle>}
        </Container >
    );
};

export default UnitForm;