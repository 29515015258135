import React from 'react';
import './App.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router } from "react-router-dom";
import { persistor, store } from './redux/store';
import { RootRoutes } from './routing/routes';
import ThemeProvider from './theme';

function App(): React.ReactElement {
  return (
      <Provider store={store}>
          <PersistGate persistor={persistor}>
              <ThemeProvider>
                  <Router>
                      <RootRoutes />
                  </Router>
              </ThemeProvider>
          </PersistGate>
      </Provider>
  );
}

export default App;
